export const sygnet = [
  '273 273',
  `
    <title>kane logo</title>
    <g>
      <g style="fill:currentColor">
        <path d="m257.92,137.23c0,40.54 0,81.08 0,121.62c0,4.62 0.16,4.39 -4.26,4.39c-79.25,0 -158.51,0 -237.76,0c-4.63,0 -4.29,0.09 -4.29,-4.34c0,-81.24 0,-162.48 0,-243.72q0,-4.33 4.36,-4.34c79.17,0 158.35,0 237.52,0q4.42,0 4.42,4.53c0,40.62 0,81.24 0,121.86l0.01,0zm-8.31,0c0,-38.06 0,-76.13 0,-114.19q0,-3.5 -3.58,-3.51c-74.14,0 -148.28,0 -222.42,0q-3.65,0 -3.65,3.69c0,75.89 0,151.78 0,227.67q0,3.67 3.71,3.68c74.06,0 148.12,0 222.18,0q3.76,0 3.76,-3.86c0,-37.82 0,-75.65 0,-113.47l0,-0.01z"/>
        <path d="m66.13,167.01c-0.36,1.01 -0.22,1.89 -0.21,2.76c0.04,16.55 -0.06,33.1 0.23,49.64c0.12,6.7 0,13.43 0.68,20.12c0.06,0.55 0.05,1.12 0.02,1.68c-0.05,0.87 -0.49,1.35 -1.4,1.18c-1.91,-0.36 -3.81,-0.37 -5.72,0c-0.91,0.17 -1.4,-0.29 -1.42,-1.16c-0.03,-1.36 -0.1,-2.73 0.05,-4.07c0.25,-2.31 0.23,-4.63 0.3,-6.94c0.83,-28.29 0.6,-56.58 0.49,-84.88c0,-0.4 -0.02,-0.8 0,-1.2c0.03,-0.64 0,-1.33 0.7,-1.66c0.79,-0.37 1.57,-0.28 2.25,0.27c0.37,0.3 0.66,0.69 0.98,1.05c11.75,13.19 23.47,26.4 35.26,39.55c5.87,6.54 11.86,12.98 17.81,19.45c4.01,4.35 8.03,8.68 12.07,13c0.59,0.63 1.08,1.4 2.07,1.73c0.66,-0.73 0.41,-1.65 0.41,-2.47c-0.02,-14.39 0.04,-28.78 -0.13,-43.16c-0.1,-8.3 -0.24,-16.62 -0.77,-24.91c-0.02,-0.32 -0.03,-0.64 -0.01,-0.96c0.09,-1.44 0.32,-1.68 1.73,-1.52c1.68,0.19 3.34,0.23 5.02,0.04c1.67,-0.19 1.87,-0.05 1.87,1.58c0,2.56 -0.11,5.11 -0.19,7.67c-0.03,0.96 -0.16,1.91 -0.19,2.87c-0.83,26.61 -0.61,53.22 -0.51,79.84c0,1.92 0.07,3.84 0,5.75c-0.06,1.6 -1.3,2.24 -2.62,1.35c-0.65,-0.44 -1.18,-1.08 -1.68,-1.69c-10.24,-12.48 -20.88,-24.62 -31.63,-36.67c-7.08,-7.93 -14.29,-15.73 -21.47,-23.57c-4.1,-4.48 -8.25,-8.91 -12.4,-13.35c-0.42,-0.45 -0.76,-1.04 -1.6,-1.28l0.01,-0.04z"/>
        <path d="m70.06,80.75c1.23,-0.21 1.86,-0.97 2.53,-1.62c9.22,-8.98 18.37,-18.02 26.9,-27.68c3.97,-4.49 7.99,-8.94 11.88,-13.49c1.29,-1.51 2.67,-2.1 4.61,-1.68c2.45,0.53 4.92,0.44 7.39,0.02c0.62,-0.11 1.26,-0.29 1.96,0.13c0,1.09 -0.97,1.55 -1.6,2.17c-7.13,6.88 -14.33,13.69 -21.42,20.61c-6.12,5.97 -12.24,11.96 -17.91,18.37c-0.16,0.18 -0.33,0.35 -0.49,0.53c-2.27,2.52 -2.24,2.5 -0.08,5.03c11.84,13.85 23.67,27.7 35.51,41.56c2.23,2.61 4.48,5.21 6.71,7.83c0.35,0.41 0.84,0.76 0.85,1.4c-0.26,0.59 -0.78,0.45 -1.24,0.41c-4.38,-0.41 -8.76,-0.69 -13.13,0.04c-1.24,0.21 -1.91,-0.46 -2.59,-1.31c-4.6,-5.74 -9.22,-11.46 -13.84,-17.18c-7.68,-9.51 -15.47,-18.94 -23.59,-28.09c-0.67,-0.75 -1.22,-1.67 -2.21,-2.03c-0.68,0.44 -0.5,1.06 -0.5,1.58c0.03,9.11 0,18.23 0.16,27.34c0.1,5.59 0.55,11.17 0.78,16.75c0.13,3.04 0.06,3.16 -3.03,2.73c-2.88,-0.4 -5.72,-0.34 -8.59,0.02c-2.29,0.29 -2.43,0.14 -2.37,-2.03c0.17,-5.83 0.28,-11.67 0.67,-17.48c0.28,-4.08 0.27,-8.15 0.28,-12.22c0.03,-13.99 0.08,-27.98 -0.07,-41.96c-0.07,-6.94 -0.57,-13.88 -0.85,-20.83c-0.15,-3.68 -0.1,-3.66 3.51,-3.22c2.63,0.32 5.25,0.28 7.88,-0.13c2.22,-0.35 2.64,-0.02 2.58,2.18c-0.21,7.19 -0.5,14.37 -0.76,21.55c-0.24,6.63 -0.26,13.26 -0.18,19.9c0,0.22 0.13,0.45 0.25,0.82l0,-0.02z"/>
        <path d="m178,96.25c-4.8,0 -9.6,0.07 -14.39,-0.03c-2.08,-0.05 -3.18,0.77 -3.91,2.69c-4.13,11 -8.42,21.94 -11.86,33.19c-0.72,2.34 -0.72,2.4 -3.17,1.97c-2,-0.35 -3.96,-0.38 -5.95,0.03c-0.58,0.12 -1.33,0.47 -1.76,-0.2c-0.35,-0.53 0.13,-1.07 0.35,-1.56c1.99,-4.45 3.99,-8.9 6,-13.34c9.62,-21.27 18.89,-42.69 28.08,-64.15c2.58,-6.03 5.12,-12.07 7.7,-18.09c0.37,-0.86 0.6,-1.84 1.79,-2.04c1.48,-0.24 2.29,0.12 2.99,1.72c1.44,3.3 2.81,6.62 4.2,9.94c9.31,22.36 18.49,44.78 28.19,66.98c2.69,6.15 5.44,12.28 8.17,18.41c0.19,0.44 0.4,0.87 0.57,1.32c0.31,0.82 0.01,1.23 -0.87,1.19c-0.24,0 -0.48,-0.01 -0.72,-0.06c-3.74,-0.71 -7.48,-0.33 -11.23,0.02c-1.9,0.18 -1.95,0.13 -2.56,-1.7c-3.86,-11.45 -8.62,-22.55 -13.01,-33.79c-0.73,-1.87 -1.77,-2.59 -3.73,-2.56c-4.96,0.08 -9.92,0.03 -14.87,0.03c0,0 0,0.02 0,0.03l-0.01,0zm0.17,-40.84c-0.67,0.59 -0.85,1.47 -1.18,2.25c-1.6,3.75 -3.17,7.51 -4.74,11.27c-2.5,5.97 -5,11.94 -7.47,17.93c-0.9,2.17 -0.7,2.43 1.68,2.52c0.08,0 0.16,0 0.24,0c7.83,0 15.67,0 23.5,0c0.56,0 1.13,-0.01 1.67,-0.11c0.88,-0.16 1.2,-0.72 0.9,-1.58c-0.13,-0.38 -0.3,-0.74 -0.46,-1.11c-4.45,-9.74 -8.65,-19.59 -12.87,-29.44c-0.27,-0.62 -0.34,-1.42 -1.26,-1.73l-0.01,0z"/>
        <path d="m170.93,192.73c0,-6.66 0.12,-13.86 -0.03,-21.05c-0.17,-8.06 -0.61,-16.12 -0.88,-24.19c-0.13,-3.86 -0.09,-3.79 3.74,-3.51c11.33,0.83 22.68,0.86 34.03,0.5c3.19,-0.1 6.37,-0.45 9.56,-0.69c1.97,-0.15 2.39,0.22 2.07,2.09c-0.26,1.52 -0.28,3 -0.02,4.52c0.27,1.64 -0.03,1.98 -1.77,1.96c-1.75,-0.02 -3.5,-0.29 -5.26,-0.33c-8.79,-0.22 -17.57,-0.77 -26.36,-0.62c-2.15,0.04 -2.31,0.21 -2.43,2.31c-0.59,10.3 -0.49,20.61 -0.53,30.92c-0.01,2.5 0.23,2.72 2.76,2.73c9.19,0.04 18.38,-0.2 27.55,-0.9c0.48,-0.04 0.96,-0.08 1.44,-0.06c1.44,0.06 1.67,0.27 1.51,1.74c-0.19,1.76 -0.17,3.5 -0.02,5.26c0.12,1.5 -0.04,1.66 -1.56,1.68c-1.28,0.02 -2.56,-0.04 -3.83,-0.12c-8.29,-0.55 -16.6,-0.85 -24.91,-0.84c-0.48,0 -0.96,0.02 -1.44,0.04c-0.98,0.04 -1.42,0.6 -1.46,1.54c0,0.16 -0.02,0.32 -0.02,0.48c0.15,7.43 -0.07,14.86 0.32,22.29c0.23,4.39 0.15,8.79 0.21,13.18c0.01,0.72 0.02,1.44 0.05,2.16c0.03,0.98 0.58,1.44 1.53,1.45c0.56,0.01 1.12,0.02 1.68,0.02c5.12,-0.02 10.23,0.04 15.35,-0.08c5.19,-0.12 10.38,-0.28 15.55,-0.78c0.48,-0.05 0.96,-0.06 1.44,-0.04c0.8,0.03 1.17,0.48 1.05,1.26c-0.3,1.99 -0.33,3.98 0,5.97c0.18,1.11 -0.48,1.44 -1.42,1.38c-1.19,-0.08 -2.38,-0.26 -3.58,-0.34c-10.22,-0.69 -20.45,-0.76 -30.68,-0.47c-3.75,0.11 -7.49,0.49 -11.24,0.75c-0.48,0.03 -0.96,0.08 -1.44,0.07c-1.52,-0.05 -1.86,-0.31 -1.88,-1.83c-0.03,-2.24 -0.02,-4.48 0.12,-6.71c0.85,-13.73 0.96,-27.47 0.82,-41.75l-0.02,0.01z"/>
      </g>
    </g>
  `,
]
