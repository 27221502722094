/* eslint-disable react/prop-types */
import React, { createContext, useContext, useMemo, useState } from 'react'

const TransContext = createContext()

export const TransProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [certificate, setCertificate] = useState({})

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      certificate,
      setCertificate,
    }),
    [isLoading, certificate],
  )

  return <TransContext.Provider value={value}>{children}</TransContext.Provider>
}

export const useTrans = () => {
  return useContext(TransContext)
}
