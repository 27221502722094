import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTrans } from '../hooks/useTrans'

const FadeLoader = ({ children }) => {
  const { isLoading } = useTrans()
  // const { children, active, spinner } = props

  useEffect(() => {
    if (isLoading) document.body.style.overflow = 'hidden'
    else {
      document.body.style.removeProperty('overflow')
      if (document.body.style.length === 0) document.body.removeAttribute('style')
    }
  }, [isLoading])

  return (
    <>
      {children}
      {isLoading && (
        <div className="fade-loader d-flex modal-backdrop show">
          <div className="m-auto">
            <div className="spinner1"></div>
            <div className="spinner2"></div>
            <div className="spinner3"></div>
          </div>
        </div>
      )}
    </>
  )
}

FadeLoader.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  spinner: PropTypes.node,
}

export default FadeLoader
