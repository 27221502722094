import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  CButton,
  CModal,
  CModalFooter,
  CModalHeader,
  CModalBody,
  CFormRange,
} from '@coreui/react-pro'
import AvatarEditor from 'react-avatar-editor'

const ImageUploader = (props) => {
  const { image, onChangeImage } = props
  const [imageBlob, setImageBlob] = useState(image)
  const [newImageBlob, setNewImageBlob] = useState(image)
  const [modal, setModal] = useState(false)
  const [slideValue, setSlideValue] = useState(10)
  const cropRef = useRef(null)

  useEffect(() => {
    setImageBlob(image)
  }, [image])

  const handleImageChange = (e) => {
    e.preventDefault()
    setModal(true)
    const reader = new FileReader()
    const file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        setNewImageBlob(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSave = (e) => {
    e.preventDefault()
    if (cropRef) {
      const result = cropRef.current.getImageScaledToCanvas()
      result.toBlob((blob) => {
        if (blob) {
          onChangeImage({ file: blob, result: URL.createObjectURL(blob) })
        }
      })
      setModal(false)
      result.toDataURL()
    }
  }

  return (
    <>
      <div style={{ width: '120px', height: '120px', position: 'relative' }}>
        <div className="d-flex h-100 w-100 text-center align-items-center k-bg-2 rounded-4 overflow-hidden">
          {imageBlob == null ? (
            'Выберите изображение'
          ) : (
            <img
              src={imageBlob}
              alt=""
              style={{
                display: 'block',
                margin: 'auto',
                height: 'auto',
                maxHeight: '100%',
                width: 'auto',
                maxWidth: '100%',
              }}
            />
          )}
        </div>
        <input
          onChange={(e) => handleImageChange(e)}
          type="file"
          accept="image/*"
          name="file"
          style={{
            position: 'absolute',
            opacity: 0,
            inset: 0,
            cursor: 'pointer',
          }}
        />
      </div>
      {modal && (
        <CModal visible={modal} backdrop="static" onClose={() => setModal(false)}>
          <CModalHeader closeButton>
            <b>{'Подготовка аватарки'}</b>
          </CModalHeader>
          <CModalBody>
            <AvatarEditor
              ref={cropRef}
              image={newImageBlob}
              style={{ width: '100%', height: '100%' }}
              border={50}
              color={[0, 0, 0, 0.72]}
              scale={slideValue / 10}
              rotate={0}
            />
            <CFormRange
              min={10}
              max={50}
              value={slideValue}
              onChange={(e) => setSlideValue(e.target.value)}
            />
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={(e) => handleSave(e)}>
              Применить
            </CButton>
            <CButton color="secondary" onClick={() => setModal(false)}>
              Отмена
            </CButton>
          </CModalFooter>
        </CModal>
      )}
    </>
  )
}

ImageUploader.propTypes = {
  image: PropTypes.any,
  onChangeImage: PropTypes.func,
}
export default React.memo(ImageUploader)
