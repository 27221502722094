import React, { useEffect, useState } from 'react'
import { CAvatar } from '@coreui/react-pro'
import { useApi } from '../hooks/useApi'
import { isNumber } from 'chart.js/helpers'
import defaultAvatar from './../assets/images/avatar-placeholder.png'
import PropTypes from 'prop-types'

const ServerAvatar = ({ item, ...rest }) => {
  const [blob, setBlob] = useState(defaultAvatar)
  const api = useApi()

  useEffect(() => {
    setBlob(defaultAvatar)
    if (item.hasOwnProperty('avatarId') && item.avatarId && isNumber(item.avatarId))
      api.getPicture({ file_id: item.avatarId }).then((url) => {
        if (url) setBlob(url)
      })
    else if (item.hasOwnProperty('id') && item.id && isNumber(item.id))
      api.getPicture({ account_id: item.id }).then((url) => {
        if (url) setBlob(url)
        else
          api
            .get(api.endpoint.VKPHOTO, { account_id: item.id }, { onError: () => {} })
            .then((data) => {
              if (data.photoUrl) setBlob(data.photoUrl)
            })
            .catch(() => {})
      })
  }, [item])

  return <CAvatar src={blob} className="k-avatar" {...rest} />
}

ServerAvatar.propTypes = {
  item: PropTypes.object,
}
export default React.memo(ServerAvatar)
