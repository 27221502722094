import React, { Suspense, useEffect } from 'react'
import { useLocation, HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ProtectedRoute } from './layout/index'
import { AuthProvider, useAuth } from './hooks/useAuth'

import { CSpinner, useColorModes } from '@coreui/react-pro'
import './scss/style.scss'

// We use those styles to show code examples, you should remove them in your application.
// import './scss/examples.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const YmTrackPageView = () => {
  const location = useLocation()

  useEffect(() => {
    window.ym(97789007, 'hit', window.location.href)
  }, [location.pathname])

  return null
}

const YmTrackRoles = () => {
  //Передает параметры в Яндекс.Метрику для сегментации пользователей по ролям
  const { Roles, checkRoles } = useAuth()
  if (checkRoles(Roles.ADMIN)) {
    window.ym(97789007, 'params', { role: 'АДМИН' })
  }
  if (checkRoles(Roles.INSTRUCTOR)) {
    window.ym(97789007, 'params', { role: 'ИНСТРУКТОР' })
  }

  return null
}

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('kanepro-ru-theme')
  const storedTheme = useSelector((state) => state.theme)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    // принудительно тема, пока без переключателя
    setColorMode('light')

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HashRouter>
      <YmTrackPageView />
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <AuthProvider>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            {/*<Route exact path="/register" name="Register Page" element={<Register />} />*/}
            {/*<Route exact path="/404" name="Page 404" element={<Page404 />} />*/}
            {/*<Route exact path="/500" name="Page 500" element={<Page500 />} />*/}
            <Route
              path="*"
              name="Home"
              element={
                <ProtectedRoute>
                  <DefaultLayout />
                  <YmTrackRoles />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Suspense>
    </HashRouter>
  )
}

export default App
