import React, { useEffect, useState } from 'react'
import { useIMask } from 'react-imask'
import { CFormInput } from '@coreui/react-pro'
import PropTypes from 'prop-types'

const CFormInputMobile = ({ phone, onChangeMasked, ...rest }) => {
  const [realValue, setRealValue] = useState('')

  const [opts] = useState({
    unmask: true,
    mask: [
      {
        mask: '+0(000)000-00-00',
        startsWith: '7',
        country: 'Russia',
      },
      {
        mask: '+000(00)000-000',
        startsWith: '371',
        country: 'Latvia',
      },
      {
        mask: '+000(00)000-00-00',
        startsWith: '375',
        country: 'Belarus',
      },
      {
        mask: '+000(00)000-00-00',
        startsWith: '380',
        country: 'Ukraine',
      },
      {
        mask: '+00(00)000-00-00',
        startsWith: '48',
        country: 'Poland',
      },
      {
        mask: '+00(0000)000-00-00',
        startsWith: '49',
        country: 'Germany',
      },
      {
        mask: '+00(000)000-00-00',
        startsWith: '90',
        country: 'Turkey',
      },
      {
        mask: '+{7}(000)000-00-00',
        startsWith: '',
        country: 'RussiaDefault',
      },
    ],
    prepare: (appended, masked) => {
      if (masked.value === '' && appended === '8') return '7'
      return appended
    },
    dispatch: (appended, dynamicMasked) => {
      const number = (dynamicMasked.value + appended).replace(/\D/g, '')
      let mas = dynamicMasked.compiledMasks.find((m) => m.isFilled)
      if (mas) return mas
      mas = dynamicMasked.compiledMasks.find((m) => {
        return number.indexOf(m.startsWith.substring(0, number.length)) === 0
      })
      return mas
    },
  })

  // костыль
  // opts.unmask почему-то не отрабатывает
  const onAccept = (value, masked) => {
    if (realValue && onChangeMasked) onChangeMasked('')
    setRealValue('')
  }
  const onComplete = (value, masked) => {
    let _value = value
    if (opts.unmask) _value = masked.masked.unmaskedValue
    setRealValue(_value)
    if (onChangeMasked) onChangeMasked(_value)
  }

  const {
    ref,
    // maskRef,
    // value,
    // setValue,
    // unmaskedValue,
    setUnmaskedValue,
    // typedValue,
    // setTypedValue,
  } = useIMask(opts, { onAccept: onAccept, onComplete: onComplete })

  useEffect(() => {
    if (phone === undefined || phone.length > 0) {
      if (phone === undefined) setRealValue('')
      setUnmaskedValue(phone || '')
    }
  }, [phone])

  return <CFormInput {...rest} ref={ref} />
}

CFormInputMobile.propTypes = {
  phone: PropTypes.string,
  onChangeMasked: PropTypes.func,
}

export default React.memo(CFormInputMobile)
