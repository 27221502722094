import React from 'react'
import { Translation } from 'react-i18next'

const Account = React.lazy(() => import('./views/account/Account'))
const AccountEdit = React.lazy(() => import('./views/account/AccountEdit'))
const Calendar = React.lazy(() => import('./views/certificate/Calendar'))
const Certificate = React.lazy(() => import('./views/certificate/Certificate'))
const CertificateEdit = React.lazy(() => import('./views/certificate/CertificateEdit'))
const Course = React.lazy(() => import('./views/dictionary/Course'))
const City = React.lazy(() => import('./views/dictionary/City'))
const DashboardAdmin = React.lazy(() => import('./views/dashboard/Admin'))
const DashboardInstructor = React.lazy(() => import('./views/dashboard/Instructor'))
const DashboardParent = React.lazy(() => import('./views/dashboard/Parent'))
const Instructors = React.lazy(() => import('./views/dictionary/Instructors'))
const Seminar = React.lazy(() => import('./views/certificate/Seminar'))
const SeminarCertificate = React.lazy(() => import('./views/certificate/SeminarCertificate'))
const Order = React.lazy(() => import('./views/order/Order'))
const Team = React.lazy(() => import('./views/dictionary/Team'))

const routes = {
  ADMIN: [
    { path: '/', exact: true, name: <Translation>{(t) => t('home')}</Translation> },
    {
      path: '/certificate',
      name: 'Сертификаты',
      element: Certificate,
    },
    {
      path: '/certificate/create',
      name: 'Создание',
      element: CertificateEdit,
    },
    {
      path: '/course',
      name: 'Курсы',
      element: Course,
    },
    {
      path: '/dashboard',
      name: 'Панель администратора',
      element: DashboardAdmin,
    },
    {
      path: '/profile',
      name: 'Профиль',
      element: AccountEdit,
    },
    {
      path: '/report/instructor',
      name: 'Отчёт инструктора',
      element: DashboardInstructor,
    },
    {
      path: '/report/parent',
      name: 'Отчёт наставника',
      element: DashboardParent,
    },
    {
      path: '/seminar',
      name: 'Список мероприятий',
      element: Seminar,
    },
    {
      path: '/seminar/certificate',
      name: 'Участники мероприятия',
      element: SeminarCertificate,
    },
    {
      path: '/user',
      name: 'Контакты',
      element: Account,
    },
    {
      path: '/user/:accountId/edit',
      name: 'Редактирование',
      element: AccountEdit,
    },
    // {
    //   path: '/user/create',
    //   name: 'Создание',
    //   element: AccountEdit,
    // },
    {
      path: '/city',
      name: 'Города',
      element: City,
    },
    {
      path: '/order',
      name: 'Заказы',
      element: Order,
    },
    {
      path: '/seminar/calendar',
      name: 'Планер',
      element: Calendar,
    },
    {
      path: '/team',
      name: 'Команды',
      element: Team,
    },
    {
      path: '/instructors',
      name: 'Инструкторы',
      element: Instructors,
    },
  ],
  INSTRUCTOR: [
    { path: '/', exact: true, name: <Translation>{(t) => t('home')}</Translation> },
    {
      path: '/dashboard',
      name: 'Отчёт инструктора',
      element: DashboardInstructor,
    },
    {
      path: '/profile',
      name: 'Профиль',
      element: AccountEdit,
    },
    {
      path: '/user',
      name: 'Контакты',
      element: Account,
    },
    {
      path: '/user/:accountId/edit',
      name: 'Редактирование',
      element: AccountEdit,
    },
    // {
    //   path: '/user/create',
    //   name: 'Создание',
    //   element: AccountEdit,
    // },
    {
      path: '/certificate',
      name: 'Сертификаты',
      element: Certificate,
    },
    {
      path: '/seminar/certificate',
      name: 'Участники мероприятия',
      element: SeminarCertificate,
    },
    {
      path: '/certificate/create',
      name: 'Подбор',
      element: CertificateEdit,
    },
    {
      path: '/seminar',
      name: 'Список мероприятий',
      element: Seminar,
    },
    {
      path: '/seminar/calendar',
      name: 'Планер',
      element: Calendar,
    },
    {
      path: '/order',
      name: 'Заказы',
      element: Order,
    },
  ],
  PARENT: [
    { path: '/', exact: true, name: <Translation>{(t) => t('home')}</Translation> },
    {
      path: '/dashboard',
      name: 'Отчёт наставника',
      element: DashboardParent,
    },
    {
      path: '/profile',
      name: 'Профиль',
      element: AccountEdit,
    },
    {
      path: '/user',
      name: 'Контакты',
      element: Account,
    },
    {
      path: '/user/:accountId/edit',
      name: 'Редактирование',
      element: AccountEdit,
    },
    // {
    //   path: '/user/create',
    //   name: 'Создание',
    //   element: AccountEdit,
    // },
    {
      path: '/certificate',
      name: 'Сертификаты',
      element: Certificate,
    },
    {
      path: '/seminar/certificate',
      name: 'Участники мероприятия',
      element: SeminarCertificate,
    },
    {
      path: '/certificate/create',
      name: 'Подбор',
      element: CertificateEdit,
    },
    {
      path: '/seminar',
      name: 'Список мероприятий',
      element: Seminar,
    },
    {
      path: '/seminar/calendar',
      name: 'Планер',
      element: Calendar,
    },
    {
      path: '/order',
      name: 'Заказы',
      element: Order,
    },
  ],
  SALES: [
    { path: '/', exact: true, name: <Translation>{(t) => t('home')}</Translation> },
    {
      path: '/dashboard',
      name: 'Отчёт инструктора',
      element: DashboardInstructor,
    },
    {
      path: '/profile',
      name: 'Профиль',
      element: AccountEdit,
    },
    {
      path: '/user',
      name: 'Контакты',
      element: Account,
    },
    {
      path: '/user/:accountId/edit',
      name: 'Редактирование',
      element: AccountEdit,
    },
    {
      path: '/certificate',
      name: 'Сертификаты',
      element: Certificate,
    },
    {
      path: '/seminar/certificate',
      name: 'Участники мероприятия',
      element: SeminarCertificate,
    },
    {
      path: '/certificate/create',
      name: 'Подбор',
      element: CertificateEdit,
    },
    {
      path: '/seminar',
      name: 'Список мероприятий',
      element: Seminar,
    },
    {
      path: '/seminar/calendar',
      name: 'Планер',
      element: Calendar,
    },
    {
      path: '/order',
      name: 'Заказы',
      element: Order,
    },
  ],
}

export default routes
