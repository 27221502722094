import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro'

import routes from '../routes'
import { useAuth } from '../hooks/useAuth'
import PropTypes from 'prop-types'

const AppBreadcrumb = ({ userRoutes }) => {
  const currentLocation = useLocation().pathname
  const { t } = useTranslation()
  // const { Roles, checkRoles } = useAuth()

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, userRoutes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <>
      {userRoutes.length > 0 && (
        <>
          <div className="fs-2 fw-semibold">{[...breadcrumbs].pop().name}</div>
          <CBreadcrumb className="mb-4">
            <CBreadcrumbItem href="/">{t('home')}</CBreadcrumbItem>
            {breadcrumbs.map((breadcrumb, index) => {
              if (!breadcrumb.active)
                return (
                  <CBreadcrumbItem
                    {...(breadcrumb.active
                      ? { active: true }
                      : { href: '/#' + breadcrumb.pathname })}
                    key={index}
                  >
                    {breadcrumb.name}
                  </CBreadcrumbItem>
                )
            })}
          </CBreadcrumb>
        </>
      )}
    </>
  )
}

AppBreadcrumb.propTypes = {
  userRoutes: PropTypes.array,
}
export default React.memo(AppBreadcrumb)
