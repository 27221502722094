export const logo = [
  '880 273',
  `
    <title>kane logo</title>
    <g>
      <g style="fill:currentColor">
        <path d="m257.92,137.23c0,40.54 0,81.08 0,121.62c0,4.62 0.16,4.39 -4.26,4.39c-79.25,0 -158.51,0 -237.76,0c-4.63,0 -4.29,0.09 -4.29,-4.34c0,-81.24 0,-162.48 0,-243.72q0,-4.33 4.36,-4.34c79.17,0 158.35,0 237.52,0q4.42,0 4.42,4.53c0,40.62 0,81.24 0,121.86l0.01,0zm-8.31,0c0,-38.06 0,-76.13 0,-114.19q0,-3.5 -3.58,-3.51c-74.14,0 -148.28,0 -222.42,0q-3.65,0 -3.65,3.69c0,75.89 0,151.78 0,227.67q0,3.67 3.71,3.68c74.06,0 148.12,0 222.18,0q3.76,0 3.76,-3.86c0,-37.82 0,-75.65 0,-113.47l0,-0.01z"/>
        <path d="m459.84,183.24c0.91,-0.79 1.76,-1.62 2.68,-2.33c6.24,-4.83 13.24,-5.71 20.65,-3.6c3.31,0.94 6,2.93 7.83,5.97c1.05,1.75 1.16,1.76 2.53,0.27c4.49,-4.87 10.1,-7.19 16.65,-7.27c9.98,-0.13 16.59,6.01 17.48,15.98c0.42,4.63 0.31,9.26 0.33,13.9c0.02,5.36 0,10.71 0,16.07c0,0.64 0.02,1.28 -0.03,1.92c-0.15,1.75 -0.44,2.05 -2.21,2.12c-1.36,0.05 -2.72,0 -4.08,-0.02c-1.06,-0.02 -1.62,-0.59 -1.69,-1.63c-0.05,-0.8 -0.07,-1.6 -0.07,-2.4c0,-8.31 0,-16.63 0,-24.94c0,-1.76 -0.01,-3.51 -0.45,-5.24c-1.55,-6.12 -5.86,-8.82 -11.97,-8.27c-5.91,0.53 -10.11,4.04 -13.34,8.82c-0.67,0.99 -0.61,2.12 -0.61,3.24c0.02,8.79 0.05,17.59 0.07,26.38c0,0.64 0.02,1.28 -0.01,1.92c-0.09,1.79 -0.33,2.06 -2.13,2.13c-1.28,0.05 -2.56,0 -3.84,0c-1.16,0 -1.79,-0.56 -1.81,-1.74c-0.01,-0.72 -0.03,-1.44 -0.03,-2.16c0,-8.23 0,-16.47 0,-24.7c0,-1.84 -0.13,-3.66 -0.51,-5.48c-1.32,-6.43 -6.31,-8.86 -11.6,-8.44c-5.89,0.46 -10.21,3.79 -13.51,8.55c-0.74,1.06 -0.68,2.27 -0.68,3.47c0,8.95 0,17.91 0,26.86q0,3.66 -3.77,3.67q-4.06,0 -4.07,-4.08c0,-13.75 0,-27.5 0,-41.25c0,-3.53 0.08,-3.61 3.64,-3.62c4.15,-0.02 4.14,-0.02 4.21,4.03c0.01,0.6 -0.22,1.26 0.33,1.88l0.01,-0.01z"/>
        <path d="m295.8,77.99c0.5,-0.44 0.82,-0.67 1.08,-0.96c7.76,-8.71 25.59,-9.93 35.29,1.38c9.74,11.35 7.85,30.01 -4.29,38.74c-3.59,2.58 -7.64,3.97 -12.07,4.12c-3.04,0.1 -6.06,0.07 -9.03,-0.78c-4.27,-1.22 -7.84,-3.5 -10.86,-6.89c-0.71,0.63 -0.45,1.39 -0.45,2.02c-0.02,6.95 -0.01,13.9 -0.02,20.85c0,0.64 -0.03,1.28 -0.02,1.92c0.02,1.19 -0.6,1.79 -1.73,1.83c-1.44,0.05 -2.87,0.04 -4.31,0.02c-1.18,-0.02 -1.7,-0.73 -1.72,-1.86c-0.01,-0.64 -0.03,-1.28 -0.03,-1.92c0,-20.45 0,-40.9 0,-61.35c0,-3.78 0.05,-3.82 3.93,-3.81c3.87,0 3.86,0 3.9,3.84c0,0.85 -0.2,1.74 0.34,2.85l-0.01,0zm-0.34,17.62c0,1.6 0.14,3.21 -0.03,4.79c-0.34,3.25 1.08,5.71 3.18,7.94c1.27,1.35 2.72,2.47 4.31,3.42c8.14,4.86 18.81,2.65 24.12,-5c2.61,-3.76 3.49,-7.97 3.27,-12.51c-0.36,-7.8 -6.12,-14.88 -13.75,-16.42c-6.72,-1.35 -12.7,0.31 -17.63,5.18c-2.34,2.32 -3.99,4.97 -3.5,8.52c0.18,1.33 0.03,2.71 0.03,4.07l0,0.01z"/>
        <path d="m828.37,113.99c-0.99,0.78 -1.99,1.68 -3.09,2.43c-8.31,5.66 -17.17,6.81 -26.31,2.44c-5.32,-2.54 -7.93,-7.13 -8.1,-13.14c-0.2,-7.01 3.13,-11.67 9.23,-14.62c4.77,-2.3 9.85,-2.54 15,-1.97c3.85,0.43 7.48,1.62 10.99,3.22c1.6,0.73 1.72,0.61 1.8,-1.13c0.02,-0.4 0,-0.8 0,-1.2c0.26,-7.96 -4.15,-11.59 -11.34,-12.4c-6.33,-0.71 -12.43,0.11 -18.05,3.42c-1.8,1.06 -2.14,0.81 -2.25,-1.29c-0.02,-0.32 0,-0.64 0,-0.96c-0.03,-4.44 0,-4.58 4.09,-6.11c7.63,-2.86 15.41,-3.49 23.25,-0.96c7.88,2.54 12.02,8.23 12.12,16.49c0.08,6.31 0.02,12.62 0.02,18.94c0,0.8 -0.02,1.6 0.07,2.39c0.35,2.95 2.03,4.38 4.99,4.3q2.15,-0.06 2.21,1.97c0.12,3.88 -0.53,4.6 -4.38,4.83c-4.17,0.24 -7.47,-1.68 -9.32,-5.42c-0.2,-0.42 -0.27,-0.93 -0.93,-1.22l0,-0.01zm-0.58,-10.59c0.78,-2.87 -0.83,-4.19 -3.2,-5.27c-3.55,-1.61 -7.24,-2.56 -11.1,-2.73c-3.55,-0.16 -7,0.34 -10.25,1.95c-6.07,3 -6.02,11.57 -0.57,14.76c1.4,0.82 2.86,1.4 4.44,1.74c6.83,1.46 12.78,-0.49 18.14,-4.72c1.86,-1.46 3.25,-3.13 2.54,-5.72l0,-0.01z"/>
        <path d="m567.14,204.11c-5.28,0 -10.55,0 -15.83,0c-0.64,0 -1.28,0 -1.92,0.03c-1.15,0.04 -1.52,0.61 -1.3,1.73c0.91,4.52 3.18,8.17 6.98,10.79c4.93,3.39 10.47,4.14 16.27,3.5c3.61,-0.4 7.01,-1.5 10.24,-3.2c1.86,-0.98 2.22,-0.76 2.2,1.37c-0.05,4.61 0.93,4.47 -4.43,6.97c-2.12,0.99 -4.41,1.44 -6.7,1.77c-5.28,0.76 -10.5,0.59 -15.62,-1.11c-9.53,-3.16 -17.25,-11.86 -17.19,-23.47c0.04,-7.85 2.28,-14.61 8.09,-20.01c5.46,-5.07 12.05,-6.8 19.28,-6.03c10.76,1.15 16.85,8.32 19.43,18.75c0.5,2.03 0.64,4.11 0.7,6.18c0.06,2.07 -0.39,2.58 -2.47,2.66c-2.71,0.11 -5.43,0.05 -8.15,0.06c-3.2,0 -6.39,0 -9.59,0l0.01,0.01zm-3.58,-6.72l0,0c4.56,0 9.11,0.02 13.67,-0.02c1.94,-0.02 2.32,-0.51 1.85,-2.34c-1.44,-5.68 -5.78,-11.7 -13.95,-11.52c-1.12,0.02 -2.24,0 -3.35,0.08c-6.32,0.5 -11.86,5.21 -13.4,11.34c-0.55,2.21 -0.39,2.45 2,2.46c4.4,0.02 8.79,0 13.19,0l-0.01,0z"/>
        <path d="m511.2,97.78c-5.43,0 -10.87,0 -16.3,0c-0.56,0 -1.12,0.02 -1.68,0.01c-1.19,0 -1.48,0.59 -1.29,1.71c0.83,4.84 3.27,8.63 7.34,11.33c4.68,3.1 9.91,3.85 15.38,3.33c3.78,-0.36 7.37,-1.39 10.72,-3.23c0.28,-0.15 0.57,-0.3 0.86,-0.42c0.74,-0.3 1.33,-0.13 1.36,0.73c0.06,1.51 0.06,3.04 0,4.55c-0.04,1.11 -0.96,1.61 -1.78,2.1c-3.51,2.1 -7.36,3.09 -11.4,3.32c-2.15,0.12 -4.32,0.17 -6.47,0.02c-9.35,-0.66 -16.55,-4.96 -21.34,-13.06c-1.81,-3.07 -2.66,-6.48 -2.82,-10.06c-0.2,-4.52 0.13,-8.94 1.96,-13.16c6.19,-14.25 21.12,-17.26 31.58,-13.02c5.39,2.18 8.96,6.28 11.3,11.51c1.61,3.62 2.45,7.43 2.68,11.37c0.14,2.43 -0.35,2.94 -2.84,2.95c-5.75,0.02 -11.51,0 -17.26,0l0,0.02zm-3.76,-6.46c0.4,0 0.8,0 1.2,0c4.16,0 8.31,0.02 12.47,-0.02c1.87,-0.02 2.18,-0.42 1.91,-2.11c-0.9,-5.59 -5.96,-10.76 -11.4,-11.55c-2.3,-0.33 -4.65,-0.39 -6.93,0c-6.35,1.12 -10.42,4.92 -12.36,11c-0.75,2.35 -0.48,2.65 1.93,2.66c4.4,0.02 8.79,0 13.19,0l-0.01,0.02z"/>
        <path d="m695.79,121.33c-10.07,0.02 -17.33,-3.48 -22.65,-10.8c-6.83,-9.41 -5.69,-23.23 2.04,-31.9c9.56,-10.73 26.98,-10.41 36.24,-3.12c11.94,9.4 13.39,27.31 2.79,38.16c-4.47,4.57 -10.53,7.9 -18.43,7.67l0.01,-0.01zm-19.02,-25.57c-0.57,9.7 7.17,18.56 18,18.57c10.66,0 18.35,-7.76 18.51,-18.42c0.17,-10.85 -8.61,-18.48 -18.11,-18.56c-10.65,-0.09 -18.94,8.63 -18.39,18.42l-0.01,-0.01z"/>
        <path d="m388.07,201.92c0.58,13.12 -10.54,24.96 -24.07,25.46c-6.6,0.24 -12.7,-0.84 -18.13,-4.8c-13.35,-9.75 -12.33,-27.14 -5,-36.62c10.06,-13.01 34.68,-14.05 44.45,3.96c1.77,3.26 2.75,7.43 2.75,12.01l0,-0.01zm-26.3,-18.4c-9.84,-0.36 -18.25,7.09 -18.32,18.54c-0.06,10.44 7.97,18.34 18.4,18.32c10.44,-0.02 18.3,-7.93 18.31,-18.41c0.01,-10.66 -8.01,-18.86 -18.39,-18.46l0,0.01z"/>
        <path d="m410.86,121.28c-9.04,0.23 -16.3,-3.27 -21.72,-10.49c-6.91,-9.19 -6.86,-25.33 4.72,-34.95c9.03,-7.5 28.41,-8.93 38.28,4.75c6.01,8.32 6.73,17.43 2.36,26.51c-4.31,8.98 -11.9,13.7 -21.97,14.17c-0.56,0.03 -1.12,0 -1.68,0l0.01,0.01zm-0.04,-43.82c-9.82,-0.45 -18.22,6.98 -18.41,18.18c-0.18,10.6 7.82,18.66 18.3,18.67c10.58,0 18.37,-7.89 18.4,-18.56c0.03,-10.36 -8.01,-18.75 -18.29,-18.29z"/>
        <path d="m742.09,77.72c0.72,-0.05 1.03,-0.68 1.49,-1.08c6.57,-5.67 14.16,-7.55 22.59,-5.83c6.99,1.42 11.79,7.02 12.89,14.68c0.31,2.14 0.48,4.28 0.47,6.45c-0.04,8.08 -0.02,16.15 -0.02,24.23c0,0.64 0.02,1.28 -0.02,1.92c-0.11,1.79 -0.37,2.07 -2.14,2.14c-1.28,0.05 -2.56,0 -3.84,0c-1.15,0 -1.8,-0.54 -1.79,-1.74c0,-0.64 -0.02,-1.28 -0.02,-1.92c0,-7.68 0,-15.35 0,-23.03c0,-1.6 -0.09,-3.19 -0.32,-4.78c-1.02,-7.25 -5.31,-11.06 -12.62,-11.15c-2.11,-0.03 -4.15,0.36 -6.1,1.11c-3.65,1.41 -6.7,3.7 -9.29,6.6c-1.17,1.3 -1.67,2.79 -1.66,4.6c0.06,8.96 0.03,17.91 0.03,26.87c0,3.46 -0.02,3.46 -3.47,3.47q-4.32,0.02 -4.32,-4.38c0,-13.59 0,-27.19 0,-40.78c0,-3.72 0.08,-3.79 3.72,-3.79q4.06,0 4.08,4.14c0,0.64 0.02,1.28 0.06,1.92c0,0.12 0.17,0.24 0.28,0.37l0,-0.02z"/>
        <path d="m738.94,176.24c5.47,0.07 10.48,0.85 15.26,2.94c2.03,0.89 3.07,2.12 2.78,4.4c-0.13,1.02 -0.02,2.08 -0.11,3.11c-0.08,0.91 -0.6,1.26 -1.5,0.86c-1.61,-0.72 -3.18,-1.51 -4.82,-2.13c-5.68,-2.13 -11.52,-2.52 -17.45,-1.29c-1.59,0.33 -3.01,1.03 -4.17,2.23c-2.38,2.47 -2.08,6.13 0.69,8.15c0.72,0.52 1.52,0.87 2.35,1.17c3.01,1.1 6.08,1.96 9.22,2.62c4.32,0.9 8.51,2.21 12.32,4.53c7.34,4.46 7.89,14.6 1.11,19.87c-3.39,2.63 -7.28,3.99 -11.47,4.49c-7.2,0.85 -14.19,-0.15 -20.92,-2.82c-3.92,-1.55 -4.19,-2.01 -4.07,-6.22c0.01,-0.4 0.02,-0.8 0.06,-1.2c0.24,-2.18 0.52,-2.34 2.49,-1.22c5.85,3.31 12.13,4.87 18.85,4.62c2.57,-0.09 5.11,-0.46 7.47,-1.55c2.37,-1.1 4.12,-2.73 4.18,-5.61c0.03,-1.74 -0.62,-3.11 -1.95,-4.17c-1.14,-0.91 -2.4,-1.59 -3.82,-1.94c-3.02,-0.76 -6.05,-1.51 -9.07,-2.29c-3.57,-0.92 -7.06,-2.04 -10.34,-3.77c-4.27,-2.25 -6.51,-5.72 -6.6,-10.59c-0.09,-4.92 1.94,-8.65 6.18,-11.13c4.22,-2.48 8.89,-2.97 13.34,-3.05l-0.01,-0.01z"/>
        <path d="m610.05,121.33c-4.39,0 -9.55,-0.93 -14.51,-2.89c-0.89,-0.35 -1.75,-0.78 -2.61,-1.19c-1.11,-0.53 -1.7,-1.37 -1.64,-2.66c0.05,-1.2 -0.02,-2.4 0,-3.6c0.05,-2.3 0.36,-2.51 2.28,-1.37c3.2,1.9 6.62,3.17 10.25,3.91c4.18,0.85 8.39,1.16 12.61,0.3c2.13,-0.44 4.19,-1.05 5.81,-2.62c2.72,-2.64 2.46,-6.47 -0.59,-8.68c-1.33,-0.96 -2.84,-1.45 -4.39,-1.85c-3.25,-0.84 -6.52,-1.61 -9.76,-2.45c-3.02,-0.79 -5.94,-1.88 -8.71,-3.35c-4.39,-2.33 -6.47,-6.04 -6.46,-10.94c0.01,-4.91 2.26,-8.54 6.54,-10.93c2.7,-1.51 5.64,-2.24 8.68,-2.54c6.78,-0.67 13.39,-0.12 19.7,2.74c1.94,0.88 2.91,2.07 2.64,4.24c-0.13,1.02 0.02,2.08 -0.05,3.11c-0.08,1.33 -0.38,1.48 -1.68,0.93c-0.59,-0.25 -1.15,-0.57 -1.72,-0.86c-5.83,-2.95 -12.03,-3.62 -18.44,-2.98c-1.43,0.14 -2.83,0.54 -4.12,1.2c-2.24,1.14 -3.71,2.87 -3.64,5.46c0.07,2.52 1.67,4.07 3.87,5.01c3.02,1.29 6.19,2.14 9.41,2.78c3.07,0.61 6.05,1.49 8.94,2.67c1.48,0.6 2.86,1.42 4.26,2.18c7.08,3.83 7.54,15.35 0.21,20.22c-4.36,2.9 -9.7,4.37 -16.91,4.17l0.03,-0.01z"/>
        <path d="m418.43,176.28c5.23,0.13 10.33,0.81 15.14,3.1c1.75,0.84 2.62,1.95 2.4,3.91c-0.09,0.79 0,1.6 -0.02,2.4c-0.09,2.25 -0.4,2.47 -2.38,1.49c-3.17,-1.57 -6.45,-2.84 -9.97,-3.31c-3.89,-0.52 -7.8,-0.67 -11.67,0.31c-1.57,0.4 -2.99,1.09 -4.12,2.31c-2.26,2.43 -1.94,5.99 0.72,7.99c0.71,0.53 1.51,0.89 2.34,1.19c3,1.12 6.07,2.01 9.21,2.63c3.71,0.72 7.27,1.9 10.67,3.54c4.03,1.94 6.93,4.83 7.48,9.54c0.53,4.55 -0.86,8.36 -4.46,11.26c-3.34,2.68 -7.25,4.02 -11.44,4.55c-7.45,0.93 -14.65,-0.2 -21.59,-2.99c-0.74,-0.3 -1.44,-0.69 -2.13,-1.09c-0.75,-0.42 -1.17,-1.07 -1.16,-1.96c0,-1.68 -0.02,-3.36 0,-5.03c0.02,-0.97 0.51,-1.31 1.41,-0.85c0.92,0.47 1.82,1.01 2.75,1.47c6.66,3.23 13.64,4.44 20.97,3.15c2.22,-0.39 4.34,-1.1 5.98,-2.79c2.5,-2.57 2.21,-6.19 -0.67,-8.33c-1.04,-0.77 -2.17,-1.34 -3.43,-1.66c-3.02,-0.76 -6.04,-1.51 -9.06,-2.29c-3.64,-0.94 -7.16,-2.18 -10.56,-3.86c-8.52,-4.21 -8.43,-16.79 -0.69,-21.4c3.92,-2.34 8.2,-3.02 12.62,-3.26c0.56,-0.03 1.12,0 1.68,0l-0.02,-0.02z"/>
        <path d="m560.56,121.35c-6.23,-0.04 -11.9,-1.17 -17.34,-3.53c-2.02,-0.88 -3.08,-2.1 -2.78,-4.4c0.15,-1.1 -0.02,-2.24 0.08,-3.35c0.1,-1.16 0.5,-1.33 1.61,-0.83c0.29,0.13 0.55,0.32 0.83,0.48c5.84,3.36 12.17,4.73 18.85,4.58c1.92,-0.04 3.81,-0.4 5.67,-0.9c1.49,-0.4 2.83,-1.11 4,-2.1c2.72,-2.3 2.69,-6.04 -0.03,-8.33c-1.38,-1.16 -3.02,-1.79 -4.73,-2.23c-2.86,-0.73 -5.74,-1.4 -8.61,-2.14c-3.95,-1.02 -7.76,-2.37 -11.31,-4.48c-6.53,-3.88 -7.01,-14.88 -0.81,-19.64c3.09,-2.37 6.61,-3.52 10.39,-3.95c6.77,-0.79 13.37,-0.2 19.75,2.47c2.15,0.9 3.25,2.13 2.93,4.52c-0.13,0.94 0.02,1.92 -0.06,2.87c-0.11,1.31 -0.51,1.56 -1.71,0.97c-1.87,-0.92 -3.78,-1.7 -5.74,-2.41c-4.71,-1.7 -9.56,-1.7 -14.45,-1.28c-1.51,0.13 -2.95,0.66 -4.31,1.38c-2.09,1.11 -3.22,2.75 -3.15,5.2c0.07,2.48 1.41,4.03 3.53,4.96c3.23,1.41 6.63,2.33 10.09,3c4.11,0.79 8.03,2.13 11.71,4.13c5.8,3.15 7.99,9.14 5.68,15.31c-0.91,2.42 -2.56,4.21 -4.66,5.62c-4.77,3.2 -10.11,4.21 -15.42,4.08l-0.01,0z"/>
        <path d="m461.45,99.35c0,5.75 0,11.51 0,17.26c0,0.64 -0.02,1.28 -0.03,1.92c-0.01,1.06 -0.53,1.66 -1.6,1.67c-1.52,0.03 -3.04,0.02 -4.55,0c-1.07,-0.01 -1.59,-0.61 -1.61,-1.67c-0.02,-0.72 -0.03,-1.44 -0.03,-2.16c0,-11.11 0,-22.22 0,-33.32c0,-0.64 0,-1.28 0,-1.92c-0.04,-2.52 -0.2,-2.68 -2.76,-2.72c-1.28,-0.02 -2.56,0.01 -3.84,-0.01c-2.71,-0.05 -2.93,-0.28 -2.97,-3.09c0,-0.64 -0.03,-1.28 0.02,-1.92c0.12,-1.62 0.42,-1.98 2.01,-2.07c1.59,-0.09 3.2,-0.03 4.79,-0.05c2.53,-0.04 2.7,-0.21 2.73,-2.75c0.02,-1.76 0.02,-3.52 0,-5.27c0,-1.69 0.26,-3.34 0.75,-4.96c1.53,-5.08 4.91,-8.2 10.14,-9.01c3.73,-0.58 7.5,-0.38 11.16,0.74c2.26,0.69 2.5,1.04 2.55,3.38c0.1,4.5 0.11,4.49 -4.26,3.38c-2.33,-0.59 -4.65,-1.02 -7.09,-0.37c-2.39,0.63 -3.94,2.05 -4.73,4.32c-1.06,3.02 -0.7,6.17 -0.59,9.27c0.05,1.34 1.24,1.26 2.19,1.27c3.44,0.02 6.87,0.01 10.31,0.02c0.72,0 1.44,-0.03 2.16,0.04c1.59,0.15 1.91,0.47 1.99,2.09c0.06,1.11 0,2.24 -0.03,3.35c-0.03,0.95 -0.58,1.46 -1.49,1.56c-0.63,0.07 -1.28,0.08 -1.91,0.08c-3.52,0 -7.03,-0.01 -10.55,0.01c-2.58,0.02 -2.74,0.18 -2.75,2.7c-0.02,6.07 0,12.15 0,18.22l-0.01,0.01z"/>
        <path d="m605.77,200.11c0,-4 0,-7.99 0,-11.99q0,-3.64 -3.77,-3.64c-1.76,0 -3.52,0.04 -5.28,-0.02c-2.01,-0.06 -2.3,-0.39 -2.41,-2.43c-0.25,-4.58 -0.14,-4.7 4.48,-4.7c1.52,0 3.04,0.03 4.56,-0.01c2.1,-0.06 2.37,-0.3 2.4,-2.36c0.04,-2.88 0.01,-5.76 0.02,-8.63c0,-0.64 0.02,-1.28 0.02,-1.92c0,-1.3 0.7,-1.89 1.94,-1.91c1.2,-0.02 2.4,-0.07 3.6,0c1.9,0.11 2.21,0.42 2.24,2.32c0.05,2.8 0.02,5.6 0.02,8.39c0,0.56 -0.01,1.12 0,1.68c0.06,2.22 0.23,2.41 2.54,2.44c3.12,0.03 6.24,0 9.35,0.01c0.96,0 1.92,-0.04 2.88,0.05c1.64,0.14 1.99,0.44 2.01,2.05c0.07,5.49 0.47,5.04 -4.74,5.05c-3.2,0.01 -6.4,-0.02 -9.59,0.01c-2.26,0.03 -2.44,0.2 -2.44,2.51c-0.02,7.91 -0.03,15.83 0.01,23.74c0,1.27 0.21,2.55 0.44,3.8c0.6,3.28 2.49,5 5.85,5.35c2.74,0.28 5.41,-0.04 8.01,-1.02c1.71,-0.65 1.89,-0.49 1.95,1.36c0.04,1.04 -0.05,2.08 0.01,3.12c0.09,1.53 -0.69,2.38 -2.04,2.85c-4.68,1.59 -9.4,1.9 -14.1,0.22c-3.83,-1.38 -6.03,-4.35 -7.15,-8.15c-0.59,-2 -0.84,-4.07 -0.83,-6.16c0,-4 0,-7.99 0,-11.99l0.02,-0.02z"/>
        <path d="m693.53,176.27c3.27,0.07 7.48,0.82 11.5,2.6c1.41,0.62 2.18,1.5 2.07,3.11c-0.08,1.11 0.03,2.24 -0.05,3.36c-0.1,1.29 -0.47,1.48 -1.73,1c-0.45,-0.17 -0.87,-0.41 -1.31,-0.6c-5.38,-2.31 -10.97,-3.07 -16.64,-1.52c-8.25,2.25 -14.12,9.82 -13.12,19.78c1.11,11.01 10.71,17.7 22.25,16.2c3.28,-0.43 6.37,-1.45 9.33,-2.96c1.69,-0.86 2.14,-0.61 2.15,1.32c0.03,4.85 0.68,4.86 -4.21,6.73c-8.21,3.15 -16.47,3.32 -24.38,-0.85c-8.41,-4.43 -12.75,-11.7 -13.17,-21.13c-0.49,-11.08 4.33,-19.27 14.14,-24.41c3.74,-1.96 7.86,-2.65 13.17,-2.62l0,-0.01z"/>
        <path d="m310.64,176.26c4.28,0.09 8.41,0.85 12.36,2.56c1.5,0.65 2.17,1.58 2.18,3.29c0.02,4.86 0.08,4.88 -4.34,3.11c-4.35,-1.75 -8.88,-2.15 -13.44,-1.47c-7.84,1.16 -15.11,8.1 -15.04,17.28c0.03,4.19 0.62,8.11 3.1,11.59c3.61,5.05 8.66,7.43 14.74,7.74c4.55,0.23 8.91,-0.64 12.98,-2.77c0.42,-0.22 0.86,-0.43 1.29,-0.63c0.87,-0.4 1.46,-0.12 1.51,0.82c0.08,1.51 0.06,3.03 0,4.55c-0.04,1.02 -0.84,1.48 -1.64,1.89c-2.72,1.4 -5.6,2.33 -8.62,2.82c-5.47,0.89 -10.87,0.71 -16.01,-1.51c-9.26,-4 -14.35,-11.18 -15.4,-21.16c-1.34,-12.68 6.62,-24.05 18.96,-27.23c2.41,-0.62 4.87,-0.92 7.36,-0.86l0.01,-0.02z"/>
        <path d="m853.94,80.84c0,-8.95 0,-17.9 0,-26.84c0,-4.26 0.09,-4.33 4.24,-4.32c3.8,0 3.84,0.04 3.84,3.9c0,17.42 0,34.83 0,52.25c0,0.96 -0.02,1.92 0.04,2.88c0.24,3.96 2.83,6.08 6.76,5.52c0.94,-0.13 1.85,-0.47 2.77,-0.74c0.81,-0.24 1.24,0.1 1.34,0.88c0.21,1.6 0.08,3.19 -0.22,4.77c-0.16,0.82 -0.76,1.24 -1.47,1.42c-4.37,1.12 -8.69,1.41 -12.73,-1.11c-2.65,-1.65 -3.75,-4.29 -4.18,-7.23c-0.28,-1.89 -0.43,-3.8 -0.42,-5.73c0.03,-8.55 0.01,-17.1 0.01,-25.64l0.02,-0.01z"/>
        <path d="m359.62,78.69c1.68,-1.83 3.45,-3.53 5.57,-4.84c3.19,-1.97 6.65,-3.09 10.35,-3.52c1.97,-0.23 2.38,0.18 2.44,2.21c0.04,1.2 -0.02,2.4 0,3.59c0.03,1.26 -0.53,1.85 -1.81,2.02c-3.1,0.4 -6.1,1.19 -8.89,2.69c-2.65,1.43 -4.82,3.38 -6.65,5.75c-1.12,1.46 -1.57,3.06 -1.56,4.9c0.05,8.31 0.03,16.61 0.02,24.92c0,3.82 -0.03,3.84 -3.9,3.84q-4.18,0 -4.19,-4.27c0,-13.66 0,-27.32 0,-40.97c0,-3.64 0.1,-3.73 3.78,-3.72c3.95,0.01 4.23,0.28 4.3,4.16c0.02,1.03 0,2.05 0,3.08c0.18,0.05 0.35,0.1 0.53,0.15l0.01,0.01z"/>
        <path d="m651.98,201.83c0,7.2 0,14.39 0,21.59c0,2.68 -0.18,2.83 -2.86,2.87c-1.04,0.01 -2.08,-0.01 -3.12,-0.02c-1.16,0 -1.79,-0.56 -1.81,-1.74c-0.01,-0.64 -0.03,-1.28 -0.03,-1.92c0,-13.83 0,-27.66 0,-41.49c0,-3.71 0.07,-3.78 3.72,-3.79q4.1,-0.01 4.1,4.11c0,6.8 0,13.59 0,20.39z"/>
        <path d="m646.59,95.72c0,-6.79 0,-13.59 0,-20.38c0,-0.72 0.02,-1.44 0.03,-2.16c0.03,-1.15 0.59,-1.81 1.76,-1.83c1.44,-0.02 2.88,-0.03 4.31,0.03c1.16,0.04 1.72,0.7 1.69,1.87c-0.01,0.56 0.02,1.12 0.02,1.68c0,13.91 0,27.82 0,41.72q0,3.6 -3.57,3.61q-4.24,0.01 -4.24,-4.16c0,-6.79 0,-13.59 0,-20.38z"/>
        <path d="m648.11,155.93c3.02,0.02 5.29,2.29 5.3,5.29c0.02,3.04 -2.18,5.24 -5.27,5.27c-3.05,0.03 -5.31,-2.19 -5.33,-5.23c-0.02,-2.9 2.42,-5.35 5.3,-5.33z"/>
        <path d="m655.85,55.3c-0.05,3.1 -2.26,5.15 -5.45,5.06c-2.97,-0.08 -5.14,-2.31 -5.09,-5.23c0.05,-2.96 2.49,-5.36 5.38,-5.31c2.94,0.06 5.2,2.46 5.15,5.48l0.01,0z"/>
        <path d="m66.13,167.01c-0.36,1.01 -0.22,1.89 -0.21,2.76c0.04,16.55 -0.06,33.1 0.23,49.64c0.12,6.7 0,13.43 0.68,20.12c0.06,0.55 0.05,1.12 0.02,1.68c-0.05,0.87 -0.49,1.35 -1.4,1.18c-1.91,-0.36 -3.81,-0.37 -5.72,0c-0.91,0.17 -1.4,-0.29 -1.42,-1.16c-0.03,-1.36 -0.1,-2.73 0.05,-4.07c0.25,-2.31 0.23,-4.63 0.3,-6.94c0.83,-28.29 0.6,-56.58 0.49,-84.88c0,-0.4 -0.02,-0.8 0,-1.2c0.03,-0.64 0,-1.33 0.7,-1.66c0.79,-0.37 1.57,-0.28 2.25,0.27c0.37,0.3 0.66,0.69 0.98,1.05c11.75,13.19 23.47,26.4 35.26,39.55c5.87,6.54 11.86,12.98 17.81,19.45c4.01,4.35 8.03,8.68 12.07,13c0.59,0.63 1.08,1.4 2.07,1.73c0.66,-0.73 0.41,-1.65 0.41,-2.47c-0.02,-14.39 0.04,-28.78 -0.13,-43.16c-0.1,-8.3 -0.24,-16.62 -0.77,-24.91c-0.02,-0.32 -0.03,-0.64 -0.01,-0.96c0.09,-1.44 0.32,-1.68 1.73,-1.52c1.68,0.19 3.34,0.23 5.02,0.04c1.67,-0.19 1.87,-0.05 1.87,1.58c0,2.56 -0.11,5.11 -0.19,7.67c-0.03,0.96 -0.16,1.91 -0.19,2.87c-0.83,26.61 -0.61,53.22 -0.51,79.84c0,1.92 0.07,3.84 0,5.75c-0.06,1.6 -1.3,2.24 -2.62,1.35c-0.65,-0.44 -1.18,-1.08 -1.68,-1.69c-10.24,-12.48 -20.88,-24.62 -31.63,-36.67c-7.08,-7.93 -14.29,-15.73 -21.47,-23.57c-4.1,-4.48 -8.25,-8.91 -12.4,-13.35c-0.42,-0.45 -0.76,-1.04 -1.6,-1.28l0.01,-0.04z"/>
        <path d="m70.06,80.75c1.23,-0.21 1.86,-0.97 2.53,-1.62c9.22,-8.98 18.37,-18.02 26.9,-27.68c3.97,-4.49 7.99,-8.94 11.88,-13.49c1.29,-1.51 2.67,-2.1 4.61,-1.68c2.45,0.53 4.92,0.44 7.39,0.02c0.62,-0.11 1.26,-0.29 1.96,0.13c0,1.09 -0.97,1.55 -1.6,2.17c-7.13,6.88 -14.33,13.69 -21.42,20.61c-6.12,5.97 -12.24,11.96 -17.91,18.37c-0.16,0.18 -0.33,0.35 -0.49,0.53c-2.27,2.52 -2.24,2.5 -0.08,5.03c11.84,13.85 23.67,27.7 35.51,41.56c2.23,2.61 4.48,5.21 6.71,7.83c0.35,0.41 0.84,0.76 0.85,1.4c-0.26,0.59 -0.78,0.45 -1.24,0.41c-4.38,-0.41 -8.76,-0.69 -13.13,0.04c-1.24,0.21 -1.91,-0.46 -2.59,-1.31c-4.6,-5.74 -9.22,-11.46 -13.84,-17.18c-7.68,-9.51 -15.47,-18.94 -23.59,-28.09c-0.67,-0.75 -1.22,-1.67 -2.21,-2.03c-0.68,0.44 -0.5,1.06 -0.5,1.58c0.03,9.11 0,18.23 0.16,27.34c0.1,5.59 0.55,11.17 0.78,16.75c0.13,3.04 0.06,3.16 -3.03,2.73c-2.88,-0.4 -5.72,-0.34 -8.59,0.02c-2.29,0.29 -2.43,0.14 -2.37,-2.03c0.17,-5.83 0.28,-11.67 0.67,-17.48c0.28,-4.08 0.27,-8.15 0.28,-12.22c0.03,-13.99 0.08,-27.98 -0.07,-41.96c-0.07,-6.94 -0.57,-13.88 -0.85,-20.83c-0.15,-3.68 -0.1,-3.66 3.51,-3.22c2.63,0.32 5.25,0.28 7.88,-0.13c2.22,-0.35 2.64,-0.02 2.58,2.18c-0.21,7.19 -0.5,14.37 -0.76,21.55c-0.24,6.63 -0.26,13.26 -0.18,19.9c0,0.22 0.13,0.45 0.25,0.82l0,-0.02z"/>
        <path d="m178,96.25c-4.8,0 -9.6,0.07 -14.39,-0.03c-2.08,-0.05 -3.18,0.77 -3.91,2.69c-4.13,11 -8.42,21.94 -11.86,33.19c-0.72,2.34 -0.72,2.4 -3.17,1.97c-2,-0.35 -3.96,-0.38 -5.95,0.03c-0.58,0.12 -1.33,0.47 -1.76,-0.2c-0.35,-0.53 0.13,-1.07 0.35,-1.56c1.99,-4.45 3.99,-8.9 6,-13.34c9.62,-21.27 18.89,-42.69 28.08,-64.15c2.58,-6.03 5.12,-12.07 7.7,-18.09c0.37,-0.86 0.6,-1.84 1.79,-2.04c1.48,-0.24 2.29,0.12 2.99,1.72c1.44,3.3 2.81,6.62 4.2,9.94c9.31,22.36 18.49,44.78 28.19,66.98c2.69,6.15 5.44,12.28 8.17,18.41c0.19,0.44 0.4,0.87 0.57,1.32c0.31,0.82 0.01,1.23 -0.87,1.19c-0.24,0 -0.48,-0.01 -0.72,-0.06c-3.74,-0.71 -7.48,-0.33 -11.23,0.02c-1.9,0.18 -1.95,0.13 -2.56,-1.7c-3.86,-11.45 -8.62,-22.55 -13.01,-33.79c-0.73,-1.87 -1.77,-2.59 -3.73,-2.56c-4.96,0.08 -9.92,0.03 -14.87,0.03c0,0 0,0.02 0,0.03l-0.01,0zm0.17,-40.84c-0.67,0.59 -0.85,1.47 -1.18,2.25c-1.6,3.75 -3.17,7.51 -4.74,11.27c-2.5,5.97 -5,11.94 -7.47,17.93c-0.9,2.17 -0.7,2.43 1.68,2.52c0.08,0 0.16,0 0.24,0c7.83,0 15.67,0 23.5,0c0.56,0 1.13,-0.01 1.67,-0.11c0.88,-0.16 1.2,-0.72 0.9,-1.58c-0.13,-0.38 -0.3,-0.74 -0.46,-1.11c-4.45,-9.74 -8.65,-19.59 -12.87,-29.44c-0.27,-0.62 -0.34,-1.42 -1.26,-1.73l-0.01,0z"/>
        <path d="m170.93,192.73c0,-6.66 0.12,-13.86 -0.03,-21.05c-0.17,-8.06 -0.61,-16.12 -0.88,-24.19c-0.13,-3.86 -0.09,-3.79 3.74,-3.51c11.33,0.83 22.68,0.86 34.03,0.5c3.19,-0.1 6.37,-0.45 9.56,-0.69c1.97,-0.15 2.39,0.22 2.07,2.09c-0.26,1.52 -0.28,3 -0.02,4.52c0.27,1.64 -0.03,1.98 -1.77,1.96c-1.75,-0.02 -3.5,-0.29 -5.26,-0.33c-8.79,-0.22 -17.57,-0.77 -26.36,-0.62c-2.15,0.04 -2.31,0.21 -2.43,2.31c-0.59,10.3 -0.49,20.61 -0.53,30.92c-0.01,2.5 0.23,2.72 2.76,2.73c9.19,0.04 18.38,-0.2 27.55,-0.9c0.48,-0.04 0.96,-0.08 1.44,-0.06c1.44,0.06 1.67,0.27 1.51,1.74c-0.19,1.76 -0.17,3.5 -0.02,5.26c0.12,1.5 -0.04,1.66 -1.56,1.68c-1.28,0.02 -2.56,-0.04 -3.83,-0.12c-8.29,-0.55 -16.6,-0.85 -24.91,-0.84c-0.48,0 -0.96,0.02 -1.44,0.04c-0.98,0.04 -1.42,0.6 -1.46,1.54c0,0.16 -0.02,0.32 -0.02,0.48c0.15,7.43 -0.07,14.86 0.32,22.29c0.23,4.39 0.15,8.79 0.21,13.18c0.01,0.72 0.02,1.44 0.05,2.16c0.03,0.98 0.58,1.44 1.53,1.45c0.56,0.01 1.12,0.02 1.68,0.02c5.12,-0.02 10.23,0.04 15.35,-0.08c5.19,-0.12 10.38,-0.28 15.55,-0.78c0.48,-0.05 0.96,-0.06 1.44,-0.04c0.8,0.03 1.17,0.48 1.05,1.26c-0.3,1.99 -0.33,3.98 0,5.97c0.18,1.11 -0.48,1.44 -1.42,1.38c-1.19,-0.08 -2.38,-0.26 -3.58,-0.34c-10.22,-0.69 -20.45,-0.76 -30.68,-0.47c-3.75,0.11 -7.49,0.49 -11.24,0.75c-0.48,0.03 -0.96,0.08 -1.44,0.07c-1.52,-0.05 -1.86,-0.31 -1.88,-1.83c-0.03,-2.24 -0.02,-4.48 0.12,-6.71c0.85,-13.73 0.96,-27.47 0.82,-41.75l-0.02,0.01z"/>
      </g>
    </g>
  `,
]
