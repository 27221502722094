import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  CCloseButton,
  CSidebar,
  CSidebarBrand,
  CSidebarHeader,
  CSidebarToggler,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { logo, sygnet } from '../assets/brand'

// sidebar nav config
import AppSidebarNav from './AppSidebarNav'
import navigation from '../_nav'

import { useAuth } from '../hooks/useAuth'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const { Roles, checkRoles } = useAuth()
  const path = [
    { role: Roles.ADMIN, navigation: navigation.ADMIN },
    { role: Roles.PARENT, navigation: navigation.PARENT },
    { role: Roles.SALES, navigation: navigation.SALES },
    { role: Roles.INSTRUCTOR, navigation: navigation.INSTRUCTOR },
  ]

  return (
    <CSidebar
      colorScheme="dark"
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarHeader style={{ backgroundColor: '#00245f' }}>
        <CSidebarBrand as={NavLink} to="/">
          <CIcon customClassName="sidebar-brand-full" icon={logo} height={32} />
          <CIcon customClassName="sidebar-brand-narrow" icon={sygnet} height={32} />
        </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          dark
          onClick={() => dispatch({ type: 'set', sidebarShow: false })}
        />
        <CSidebarToggler
          onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
        />
      </CSidebarHeader>
      <AppSidebarNav items={path.find(({ role }) => checkRoles(role))?.navigation || []} />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
