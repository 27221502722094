import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormText,
  CCol,
  CRow,
  CModalFooter,
  CButton,
  CLink,
} from '@coreui/react-pro'
import { cilCaretBottom } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import { useAuth } from '../../hooks/useAuth'
import ServerAvatar from '../../components/ServerAvatar'
import { ModalMessage } from '../../components'

const AppHeaderDropdown = () => {
  const { t } = useTranslation()
  const { user, logout } = useAuth()

  const [showMessage, setShowMessage] = useState('')

  const roles = {
    ADMIN: 'Администратор',
    INSTRUCTOR: 'Инструктор',
    MASTER: 'Мастер',
    USER: 'Пользователь',
  }

  return (
    <div className="row gx-1 px-2 d-flex align-items-center">
      <CCol xs="auto">
        <ServerAvatar item={{ id: user.id }} size="md" />
      </CCol>
      <CCol xs="auto" className="d-none d-sm-flex">
        <CFormText className="text-primary fs-5 mt-0">{user?.fio || ''}</CFormText>
      </CCol>
      <CCol xs="auto">
        <CDropdown variant="nav-item" alignment="end">
          <CDropdownToggle caret={false} className="pb-0 pt-1">
            <CIcon icon={cilCaretBottom} />
          </CDropdownToggle>
          <CDropdownMenu className="bg-secondary-subtle p-3 mt-2">
            {/* CDropdownItem преобразуется в <a>, внутри которого будет <a> */}
            <div className="dropdown-item bg-white rounded p-3 mb-3 d-flex align-items-center">
              <ServerAvatar item={{ id: user.id }} size="sm" />
              <CCol className="mx-4 d-none d-sm-block">
                <CRow>{user?.fio || ''}</CRow>
                <CRow className="fs-7">{roles[user.roles] || ''}</CRow>
              </CCol>
              <CLink href="/#/profile" className="ms-3 fs-7">
                {t('profile')}
              </CLink>
            </div>
            <CDropdownItem
              href="#"
              onClick={() => setShowMessage('Вы хотите выйти?')}
              className="bg-white rounded p-2 text-center"
            >
              {t('logout')}
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CCol>
      <ModalMessage message={showMessage} onClose={() => setShowMessage('')}>
        <CModalFooter>
          <CButton color="primary" style={{ width: '100px' }} onClick={() => logout()}>
            Да
          </CButton>
          <CButton color="secondary" style={{ width: '100px' }} onClick={() => setShowMessage('')}>
            Нет
          </CButton>
        </CModalFooter>
      </ModalMessage>
    </div>
  )
}

export default React.memo(AppHeaderDropdown)
